import React, { useEffect, useState } from "react";
import { fetchDocumentById } from "src/database/operations";
import { fetchSubjectsByStudentId } from "src/database/SubjectDetails"; // Adjust the path as per your project structure

const UserPage = ({ docId, setIsOpen }) => {
  const [studentData, setStudentData] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    let userId = localStorage.getItem("UserId");
    if (!docId && !userId) {
      // Handle the case where no userId is found
      console.error("No UserId found in local storage");
      return;
    } else if (docId) {
      localStorage.setItem("UserId", docId);
      userId = docId;
    }
    setUserId(userId);
    const fetchData = async () => {
      const data = await fetchDocumentById("students", userId);
      setStudentData(data);
      if (userId) {
        const fetchedSubjects = await fetchSubjectsByStudentId(data.id);
        setSubjects(fetchedSubjects);
      }
    };

    fetchData();
  }, [docId]);

  const handleLogout = () => {
    localStorage.removeItem("UserId");
    localStorage.removeItem("User");
    setIsOpen(false);
  };

  return (
    <div>
      {studentData ? (
        <div>
          <h4 className="mb-4 text-title-400 text-3xl font-bold text-center my-2">
            {studentData.name}
          </h4>
          <div className="flex justify-between px-10 my-4 text-title-300 font-semibold">
            <h1>#{studentData.id}</h1>
            <button onClick={handleLogout}>Logout</button>
          </div>
          <div className="mt-6">
            <h2 className="text-2xl font-bold mb-2 text-center text-title-300">
              Mark Sheet
            </h2>
            <div className="flex justify-center">
              <table className="w-3/4 divide-y divide-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Subject Name
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Mark
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Grade
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {subjects.length > 0 ? (
                    subjects.map((subject, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {subject.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {subject.mark}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {subject.grade}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="px-6 py-4 text-center">
                        No subjects found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default UserPage;
