import { useState } from "react";

import { IoMdMenu } from "react-icons/io";
import { FaRegBell } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { GoBellFill } from "react-icons/go";

const navigation = [
  { name: "Home", href: "/" },
  { name: "About", href: "/about" },
  { name: "Academics", href: "/academics" },
  { name: "Contact", href: "/contact" },
];

const Navbar = () => {
  const [mobileView, setMobileView] = useState(false);

  return (
    <div className="sticky z-10 top-0 bg-custom-100 text-white shadow-lg">
      <div className="flex justify-between md:px-8 px-4 py-4 shadow-xl">
        {/* logo */}
        <div id="1" className="order-2 lg:order-1">
          {/* <h3>logo</h3> */}
          <h4 className="my-1 text-title-100 font-bold text-xl capitalize  md:text-2xl font-mulish">
            {" "}
            <span className="md:hidden">Al Zahra Academy</span>
            <span className="hidden md:inline">Al Zahra Hadiya Academy</span>
          </h4>
        </div>
        {/* Nav Sect */}
        <div id="2" className="order-1 lg:order-2">
          <div className="hidden lg:flex ">
            <div className=" flex space-x-6 lg:space-x-14 my-1">
              {navigation.map((item) => (
                <a href={item.href} key={item.name}>
                  <h5
                    className={`${
                      window.location.pathname === item.href
                        ? " bg-custom-700"
                        : " bg-custom-600"
                    } text-title-200 text-center text-sm rounded py-2 w-[120px] font-bold`}
                  >
                    {" "}
                    {item.name}
                  </h5>
                </a>
              ))}
            </div>
          </div>
          <div className="lg:hidden">
            {!mobileView ? (
              <IoMdMenu
                onClick={() => setMobileView(!mobileView)}
                className="my-[6px] md:my-2 text-2xl"
              />
            ) : (
              <RxCross2
                onClick={() => setMobileView(!mobileView)}
                className="my-1 text-2xl"
              />
            )}
          </div>
        </div>
        <div id="3" className="my-[12px] md:my-3 text-title-400 order-3">
          <GoBellFill className="text-lg" />
        </div>
      </div>
      <div className={`lg:hidden bg-custom-200 ${mobileView ? "" : "hidden"}`}>
        <div className=" divide-y-2 divide-custom-400 shadow-md">
          {navigation.map((item) => (
            <div
              key={item.name}
              className={` ${
                window.location.pathname === item.href
                  ? "text-custom-700"
                  : "text-custom-600"
              } flex justify-center font-bold`}
            >
              <a className="w-full text-center text-lg py-2" href={item.href}>
                {item.name}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
