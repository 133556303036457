import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "src/utils/firebase";

const StudentDetails = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [studentCount, setStudentCount] = useState(0);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const studentsCollection = collection(db, "students");
        const studentSnapshot = await getDocs(studentsCollection);
        const studentList = studentSnapshot.docs.map((doc) => ({
          docId: doc.id, // Keep the Firestore document ID
          ...doc.data(),
        }));
        // Sort students by id
        studentList.sort((a, b) => a.id.localeCompare(b.id, undefined, { numeric: true }));
        setStudents(studentList);
        setStudentCount(studentList.length);
      } catch (error) {
        console.error("Error fetching student data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudents();
  }, []);

  return { students, loading, studentCount };
};

export { StudentDetails };
