import React from 'react'

const About = () => {
  return (
    <div className=''>
      <div className='grid lg:grid-cols-2 grid-cols-1 my-6'>
       <div className='flex justify-center items-center bg-white mx-4 my-2'></div>
       <div className='flex justify-center items-center bg-white mx-4 my-2'></div>
       <div className='flex justify-center items-center bg-white mx-4 my-2'></div>
       <div className='flex justify-center items-center bg-white mx-4 my-2'></div>
      </div>
    </div>
  )
}

export default About    