import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import AdminBtn from "./AdminBtn";
import { StudentDetails } from "src/database/StudentDetails";
import ChangePass from "./changePass";

const AdminPage = ({setIsAdmin,setIsOpen}) => {
  const navigate = useNavigate();

  const { students, loading, studentCount } = StudentDetails();
  
  const [isModal,setIsModal]= useState(false);

  const handleStudents = () => {
    navigate("/students");
  };
  const handleExam = () => {
    navigate("/subjects");
  };
  const handleNews = () => {
    navigate("/news");
  };
  const handleImp = () => {
    navigate("/important");
  };

  const handleLogout=()=>{
    localStorage.removeItem("Admin");
    setIsAdmin(false); 
    setIsOpen(false); 
  }

  const handlePass=()=>{
    setIsModal(true)
  }

  return (
    <div>
      <div className=" text-amber-100 font-semibold lg:py-10 py-6">
        <h1 className=" text-3xl md:text-4xl text-center text-title-400">
          Students Count :{" "}
          {studentCount ? <span>{studentCount}</span> : <span>0</span>}
        </h1>
        <div className="flex justify-between mx-10 mt-4 md:mx-20 lg:mx-28">
          <button onClick={handlePass} className="  bg-custom-100 w-[100px] py-1 rounded-md " >Password</button>
          <button onClick={handleLogout} className="bg-custom-100 w-[100px] py-1 rounded-md ">Logout</button>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mx-3 my-2 md:my-4 lg:my-10">
        <AdminBtn text="Student Details" link={handleStudents} />
        <AdminBtn text="Mark Details" link={handleExam} />
        <AdminBtn text="Live News" link={handleNews} />
        <AdminBtn text="Important Dates" link={handleImp} />
      </div>
      {
        isModal && (<ChangePass setIsModal={setIsModal}/>)
      }
    </div>
  );
};

export default AdminPage;
