import React from "react";
import {BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/layout/layout";
import Home from "./pages/home";
import About from "./pages/about";
import Academics from "./pages/academics";
import Admin from "./pages/admin";
import LiveNews from "./pages/LiveNews";
import StudentData from "./pages/StudentData";
import ExamData from "./pages/ExamData";
import Impdates from "./pages/Impdates";
import SubjectPage from "./components/Academics/SubjectPage";

function App() {
  return (
    <Layout>
       <Router>
        <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/academics" element={<Academics/>} />
        <Route path="/news" element={<LiveNews/>} />
        <Route path="/students" element={<StudentData/>} />
        <Route path="/subjects" element={<ExamData/>} />
        <Route path="/important" element={<Impdates/>} />

        <Route path="/subjects/:subject" element={<SubjectPage/>} />
      </Routes> 
       </Router>
  </Layout>
  );
}

export default App;
