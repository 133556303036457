import { ref, set,update,remove } from "firebase/database";

import { collection,getDoc, addDoc, updateDoc, deleteDoc,doc } from "firebase/firestore";

import { db, rdb } from "src/utils/firebase";

const addDocument = async (collectionName, data) => {
  try {
    const docRef = await addDoc(collection(db, collectionName), data);
    // Update the document with the Firestore document ID
    await updateDoc(docRef, { docId: docRef.id });
    console.log("Document written with ID: ", docRef.id);
    return docRef;
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};


const editDocument = async (collectionName, docId, updatedData) => {
  try {
    const docRef = doc(db, collectionName, docId);
    await updateDoc(docRef, updatedData);
    console.log("Document updated with ID: ", docId);
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

const deleteDocument = async (collectionName,docId) => {
  try {
    await deleteDoc(doc(db, collectionName, docId));
    console.log("Document deleted with ID: ", docId);
  } catch (e) {
    console.error("Error deleting document: ", e);
  }
};

const fetchDocumentById = async (collectionName, docId) => {
  try {
    const docRef = doc(db, collectionName, docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const { password, ...rest } = data; // Exclude the password field
      return rest;
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document: ", error);
  }
};


// Realtime DB
const addRealtimeRecord = async (collectionName,data, recordId) => {
  try {
    await set(ref(rdb, `${collectionName}/${recordId}`), data);
    console.log("Record added with ID: ", recordId);
  } catch (e) {
    console.error("Error adding record: ", e);
  }
};

const editRealtimeRecord = async (collectionName,recordId, updatedData) => {
  try {
    const recordRef = ref(rdb, `${collectionName}/${recordId}`);
    await update(recordRef, updatedData);
    console.log("Record updated with ID: ", recordId);
  } catch (e) {
    console.error("Error updating record: ", e);
  }
};

const deleteRealtimeRecord = async (collectionName,recordId) => {
    try {
      const recordRef = ref(rdb, `${collectionName}/${recordId}`);
      await remove(recordRef);
      console.log("Record deleted with ID: ", recordId);
    } catch (e) {
      console.error("Error deleting record: ", e);
    }
  };

  const deleteMarkRecord = async (collectionName) => {
    try {
      const recordRef = ref(rdb, `${collectionName}`);
      await remove(recordRef);
      // console.log("Record deleted with ID: ", recordId);
    } catch (e) {
      console.error("Error deleting record: ", e);
    }
  };

export { addDocument, editDocument, deleteDocument,fetchDocumentById ,addRealtimeRecord, editRealtimeRecord,deleteRealtimeRecord,deleteMarkRecord };
