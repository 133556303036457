import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaGlobe } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="  text-custom-100 bottom-0   bg-custom-800 py-2  px-4">
      <div className="md:h-[170px] flex flex-col md:flex-row justify-between  md:mt-8">
        <div className=" md:w-1/2 lg:w-1/4">
          <div className="flex flex-col items-center text-sm md:text-md py-2 md:py-6">
            <h5
              className="font-readex-pro font-bold text-2xl"
              style={{ fontWeight: 900 }}
            >
              كلية الزهراء البنات
            </h5>
            <h6 className="font-bold mulish-custom text-lg">AL ZAHRA HADIYA ACADEMY</h6>
            <h6 className="font-semibold text-sm md:text-md">
              Run by:Madaru Dhae-ewathil islamiyya
            </h6>
            <h6 className="text-sm md:text-md ">
              Reg no 695/2000 p.o vatanapally,thrissur kerala{" "}
            </h6>
            <span className="block md:hidden w-3/4 border-t border-emerald-950 my-4 "></span>
          </div>
        </div>
        <div className="hidden lg:block lg:w-1/4">
          <div className="flex justify-between">
            <div className=" flex flex-col">
            <h6 className="font-semibold underline">Helpful Links</h6>
            <h6>Home</h6>
            <h6>About</h6>
            <h6>Academics</h6>
            <h6>Contact</h6>
            </div>
            <div className="flex flex-col">
            <h6 className="font-semibold underline">Facility</h6>
            <h6>Hostel</h6>
            <h6>Vehicle</h6>
            <h6>Attendance</h6>
            <h6>BA/B.Com</h6>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 lg:w-1/4 flex flex-col items-center md:py-6 pb-4 px-14">
        <div className="">
          <h6 className="font-semibold md:text-left text-center ">Connet Us</h6>
          <h5 className="text-xl font-bold">+91 95786 31388</h5>
          <h5 className="text-xl font-bold">+91 95786 31366</h5>
        </div>
         <div className="flex py-1 space-x-8 px-4 text-lg">
          <FaFacebookF/>
          <RiInstagramFill className="text-xl"/>
          <FaGlobe/>
         </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
