import React, { useState } from "react";

import { StudentDetails } from "src/database/StudentDetails";
import Loading from "src/components/Loading";

import { FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";

import { addDocument, deleteDocument, editDocument } from "src/database/operations";
import { getDocs, query, collection, where } from "firebase/firestore";
import { db } from "src/utils/firebase";

const StudentData = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [id, setId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editStudent, setEditStudent] = useState(null);


  const collectionName = "students";

  const { students, loading } = StudentDetails();
  if (loading) {
    return <Loading />;
  }

  const handleAdd = async () => {
    if (name && id && pass) {
      const studentData = { id, name, pass };
      try {
        const studentDocRef = await addDocument(collectionName, studentData);
        const userDocData = {
          username: id,
          password: pass,
          studentDocId: studentDocRef.id,
        };
        await addDocument("users", userDocData);
        window.location.reload();
      } catch (error) {
        console.error("Error adding document: ", error);
      }
      setIsOpen(false);
    }
  };

  const handleDelete = async (studentDocId) => {
    try { 
      const userDocSnap = await getDocs(
        query(
          collection(db, "users"),
          where("studentDocId", "==", studentDocId)
        )
      );
      const userDoc = userDocSnap.docs[0];

      if (userDoc) {
        await deleteDocument("users", userDoc.id); // Delete user document by ID
      }

      await deleteDocument(collectionName, studentDocId); // Delete student document
      window.location.reload();
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const handleOpen = (student = null) => {
  if (student) {
    setEditStudent(student);
    setName(student.name);
    setPass(student.pass);
    setId(student.id);
    setIsEditing(true);
  } else {
    setName("");
    setPass("");
    setId("");
    setIsEditing(false);
  }
  setIsOpen(true);
};


const handleEdit = async () => {
  if (name && id && pass && editStudent) {
    const updatedStudentData = { id, name, pass };
    try {
      // Update student data
      await editDocument(collectionName, editStudent.docId, updatedStudentData);

      // Find the user document corresponding to the student
      const userDocSnap = await getDocs(
        query(
          collection(db, "users"),
          where("studentDocId", "==", editStudent.docId)
        )
      );
      const userDoc = userDocSnap.docs[0];

      if (userDoc) {
        const updatedUserData = {
          username: id,
          password: pass,
          studentDocId: editStudent.docId,
        };
        await editDocument("users", userDoc.id,updatedUserData); // Update user data
      }

      window.location.reload();
    } catch (error) {
      console.error("Error updating document: ", error);
    }
    setIsOpen(false);
  }
};


  return (
    <div className="container mx-auto">
      <h4 className="mb-4 text-title-300 text-3xl font-bold text-center my-2">
        Student Data
      </h4>
      <div className="max-h-96 md:max-h-[540px] overflow-y-auto bg-title-400 mx-6 md:mx-0 lg:mx-20">
        <div className="flex justify-between mb-2 p-2 border-b-4 border-slate-800 mx-3 rounded-sm font-bold">
          <h3 className="md:w-1/12 w-[10px] text-start">No</h3>
          <h3 className="md:w-1/6 w-1/8 text-start">ID</h3>
          <h3 className="w-1/3 text-start">NAME</h3>
          <h3 className="w-1/3 text-start hidden md:block">PassId</h3>
          <h3 className="md:w-1/12 w-2/12 text-start">Actions</h3>
        </div>
        {students.map((student, index) => (
          <div
            key={student.docId} 
            className="flex justify-between mb-2 p-2 border-b border-slate-800 mx-3"
          >
            <h3 className="md:w-1/12 w-[10px] text-start font-semibold text-title-200">{index + 1}</h3>
            <h3 className="md:w-1/6 w-1/8 text-start font-semibold text-custom-100">{student.id}</h3>
            <h3 className="w-1/3 text-start">{student.name}</h3>
            <h3 className="w-1/3 text-start hidden md:block">{student.pass}</h3>
            <p className="md:w-1/12 w-2/12 flex md:justify-around justify-between">
            <button
              onClick={() => handleOpen(student)}
              className=" text-start"
            >
              <MdEdit className="text-xl text-custom-200" />
            </button>
            <button
              onClick={() => handleDelete(student.docId)}
              className=" text-start"
            >
              <MdDelete className="text-xl text-custom-500" />
            </button>
          </p>
          </div>
        ))}
      </div>
      <div className="flex justify-end pr-6 lg:pr-20 w-full my-3">
        <button
          onClick={()=>handleOpen()}
          className="bg-extra-600 p-3 rounded-full flex justify-center items-center shadow-lg"
        >
          <FaPlus className="text-lg text-extra-900" />
        </button>
      </div>
      {isOpen && (
      <div
        id="popup"
        className="fixed inset-0 flex items-center justify-center z-50"
      >
        <div className="absolute inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm"></div>
        <div className="relative flex flex-col items-center justify-center p-2 px-4 space-y-2 bg-extra-400 z-10 rounded-md">
          <h3 className="text-lg font-semibold text-extra-100">{isEditing ? "Edit Student" : "Add Student"}</h3>
          <input
            type="text"
            placeholder="Admission Id"
            className="pl-2 min-h-[30px] border  placeholder-slate-900 bg-extra-200"
            value={id}
            onChange={(e) => setId(e.target.value)}
            readOnly={isEditing} // Make the ID input read-only when editing
          />
          <input
            type="text"
            placeholder="Name"
            className="pl-2 min-h-[30px] border  placeholder-slate-900 bg-extra-200"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Password"
            className="pl-2 min-h-[30px]  border placeholder-slate-900 bg-extra-200"
            value={pass}
            onChange={(e) => setPass(e.target.value)}
          />
          <div className="w-full flex justify-between items-center font-semibold text-yellow-50">
            <button
              onClick={() => setIsOpen(false)}
              className="bg-custom-500 w-[80px] py-1 px-2 my-1 rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={isEditing ? handleEdit : handleAdd}
              className="bg-custom-200 w-[80px] py-1 px-2 my-1 rounded-md"
            >
              {isEditing ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </div>
    )}
    </div>
  );
};

export default StudentData;