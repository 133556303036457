import { ref, get, remove,query,orderByChild,equalTo } from "firebase/database";
import { rdb } from "src/utils/firebase";

export const SubjectData = async (collectionName) => {
  try {
    const snapshot = await get(ref(rdb, collectionName));
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.log("No data available");
      return {};
    }
  } catch (e) {
    console.error("Error fetching data: ", e);
    throw e;
  }
};

export const deleteSubject = async (collectionName, recordId) => {
  try {
    await remove(ref(rdb, `${collectionName}/${recordId}`));
    console.log("Record deleted with ID: ", recordId);
  } catch (e) {
    console.error("Error deleting record: ", e);
    throw e;
  }
};

export const MarkData = async (collectionName) => {
  try {
    const snapshot = await get(ref(rdb, collectionName));
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.log("No data available");
      return {};
    }
  } catch (e) {
    console.error("Error fetching data: ", e);
    throw e;
  }
};

export const fetchSubjectsByStudentId = async (studentId) => {
  try {
    const snapshot = await get(ref(rdb, "subjects"));
    const subjects = [];

    snapshot.forEach((subjectSnapshot) => {
      const subjectData = subjectSnapshot.val();
      if (subjectData && subjectData.marks && subjectData.marks[studentId]) {
        // If the subject has marks for the studentId, add subject details with mark and grade to the subjects array
        subjects.push({
          id: subjectSnapshot.key,
          name: subjectData.name, // Assuming subject name is stored under `name` in subject data
          mark: subjectData.marks[studentId].mark,
          grade: subjectData.marks[studentId].grade,
        });
      }
    });

    return subjects;
  } catch (error) {
    console.error("Error fetching subjects by studentId: ", error);
    throw error;
  }
};

