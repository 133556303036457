import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";

import { useNewsData } from "src/database/NoticeData";
import {
  addRealtimeRecord,
  deleteRealtimeRecord,
} from "src/database/operations";
import Loading from "src/components/Loading";

const LiveNews = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");

  const { news, loading } = useNewsData();
  if (loading) {
    return <Loading/>;
  }

  const handleOpen = async () => {
    setIsOpen(true);
  };

  const handleAdd = async () => {
    let id = uuidv4();
    const newNews = {
      id,
      title,
      description: details,
    };
    if (newNews) {
      try {
        await addRealtimeRecord('news',newNews, id);
        setIsOpen(false);
        setTitle("");
        setDetails("");
      } catch (error) {
        console.error("Error adding news:", error);
      }
    }
    setIsOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      await deleteRealtimeRecord('news',id);
    } catch (error) {
      console.error("Error deleting news:", error);
    }
  };

  return (
    <div className="lg:mx-10">
      <h4 className="mb-4 text-3xl font-bold text-center my-2 text-title-300">Live News</h4>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-4 md:my-0">
        {news.map((i) => (
          <div key={i.id} className="bg-extra-400 p-4 shadow rounded mx-4 ">
            <div className="flex items-center justify-between">
              <h4 className="text-xl font-semibold text-custom-200">{i.title}</h4>{" "}
              <button onClick={() => handleDelete(i.id)}>
                <MdDelete className="mr-4 text-xl text-custom-500" />
              </button>
            </div>
            <p className="text-sm">{i.description}</p>
          </div>
        ))}
      </div>
      <div className="flex justify-end pr-6 w-full my-3">
        <button
          onClick={handleOpen}
          className="bg-extra-600 p-3 rounded-full flex justify-center items-center shadow-lg"
        >
          <FaPlus className="text-lg text-extra-900" />
        </button>
      </div>
      {isOpen ? (
        <div
          id="popup"
          className="fixed inset-0 flex items-center justify-center z-50"
        >
          <div className="absolute inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm"></div>
          <div className="relative flex flex-col items-center justify-center p-2 px-4 space-y-2 bg-extra-400 z-10 rounded-lg">
            <h3 className="text-lg text-extra-900 font-semibold">Add News</h3>
            <input
              type="text"
              placeholder="Title"
              className="pl-2 min-h-[30px] placeholder-slate-800 border border-slate-500 bg-title-400"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <textarea
              className="px-1 min-h-[30px] pl-2 placeholder-slate-800 resize-none border border-slate-500 bg-title-400"
              placeholder="Details"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />
            <div className="w-full flex justify-between items-center text-white font-semibold">
              <button
                onClick={() => setIsOpen(false)}
                className="bg-custom-500 py-1 px-2 my-1 w-[80px] rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleAdd}
                className="bg-custom-200 py-1 px-2 my-1 w-[80px] rounded-md"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default LiveNews;