import React,{useState,useEffect} from "react";

import { useNavigate } from "react-router-dom";

import { FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { addRealtimeRecord } from "src/database/operations";
import { SubjectData } from "src/database/SubjectDetails";

const ExamData = () => {
  const navigate = useNavigate();

  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const data = await SubjectData("subjects");
        const subjectsList = Object.values(data).map(subject => subject.name);
        setSubjects(subjectsList);
      } catch (e) {
        console.error("Error fetching subjects: ", e);
      }
    };

    fetchSubjects();
  }, []);

  const handleAdd = async () => {
    const newSubject = prompt("Enter the subject name:");
    if (newSubject) {
      const recordId = newSubject.toLowerCase();
      const data = { name: newSubject };
      try {
        await addRealtimeRecord("subjects", data, recordId);
        setSubjects([...subjects, newSubject]);
      } catch (e) {
        console.error("Error adding subject: ", e);
      }
    }
  };

  const handleNavigate = (subject) => {
    // Navigate to the corresponding subpage
    navigate(`./${subject.toLowerCase()}`);
  };

  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-col justify-center items-center w-3/4 md:w-5/6 my-6">
        {" "}
        <h4 className="mb-3 text-3xl font-bold text-center my-1 text-title-300">
          Subjects
        </h4>
        <div className="space-y-2 gap-4 grid md:grid-cols-2 lg:grid-cols-4 md:space-y-0 my-4">
          {subjects.map((subject, index) => (
            <div
              key={index}
              className="bg-slate-100 p-4 h-fit rounded-md space-x-3 shadow-lg cursor-pointer text-center"
              onClick={() => handleNavigate(subject)}
            >
              <h2 className="text-lg text-title-200 font-semibold">{subject}</h2>
            </div>
          ))}
        </div>
        <div className="flex justify-end pr-6 w-full my-3 md:pr-16">
          <button
            onClick={handleAdd}
            className="bg-white p-3 rounded-full flex justify-center items-center shadow-lg"
          >
            <FaPlus className="text-lg" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamData;
