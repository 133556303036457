import { useState, useEffect } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { rdb } from "src/utils/firebase";

const useNewsData = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const dbRef = ref(rdb, 'news'); // Use the imported db directly
        onValue(dbRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const newsList = Object.keys(data).map((key) => ({
              id: key,
              ...data[key],
            }));
            setNews(newsList);
          } else {
            setNews([]);
          }
          setLoading(false);
        });
      } catch (error) {
        console.error("Error fetching news data: ", error);
        setLoading(false);
      }
    };

    fetchNews();
  }, []);
  
  return { news, loading };
};

const useImpData = () => {
  const [imp, setImp] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImp = async () => {
      try {
        const dbRef = ref(rdb, 'imp'); // Use the imported db directly
        onValue(dbRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const impList = Object.keys(data).map((key) => ({
              id: key,
              ...data[key],
            }));
            setImp(impList);
          } else {
            setImp([]);
          }
          setLoading(false);
        });
      } catch (error) {
        console.error("Error fetching news data: ", error);
        setLoading(false);
      }
    };

    fetchImp();
  }, []);
  
  return { imp, loading };
};



export { useNewsData,useImpData };
