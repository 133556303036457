import React, { useState, useEffect } from "react";
import { useImpData } from "src/database/NoticeData";
import Loading from "../Loading";

const imp = [
  {
    date:  "12 jun 2023",
    topic: "topic1",
  },
  {
    date: "12 jun 2022",
    topic: "topic2",
  },
  {
    date:  "12 jun 2024",
    topic: "topic3",
  },
];

const Updates = () => {
  const { imp, loading } = useImpData();
  if (!imp || imp.length === 0) {
    return <Loading/>;
  }
  return (
    <div className="my-6 bg-white w-[380px] md:w-[520px] rounded-lg min-h-[150px] pb-2 px-2">
      <div className="flex p-2 gap-1">
        <div className="">
          <span className="bg-blue-500 inline-block center w-3 h-3 rounded-full"></span>
        </div>
        <div className="circle">
          <span className="bg-purple-500 inline-block center w-3 h-3 rounded-full"></span>
        </div>
        <div className="circle">
          <span className="bg-pink-500 box inline-block center w-3 h-3 rounded-full"></span>
        </div>
      </div>
      <div className="card__content px-4 pb-1">
        {imp.map((item, i) => (
          <div>
          <div className="flex justify-between my-1" key={i}>
            <h5 className="text-xl font-bold">{item.topic}</h5>
            <div className="flex space-x-1 font-semibold text-lg">
              <h6>{item.date}</h6>
             
            </div>
          </div>
          <div className="border-t border-slate-300"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Updates;