import React from "react";

const AdminBtn = ({text,link}) => {
  return (
    <div className=" p-4 h-[110px] md:h-[200px]  flex justify-center items-center ">
      <div
        onClick={link}
        className="cursor-pointer shadow-md bg-title-200/35 w-[300px] h-full backdrop-blur-md flex justify-center items-center rounded-lg"
      >
        <h4 className=" text-2xl font-bold font-readex-pro text-title-300">{text}</h4>
      </div>
    </div>
  );
};

export default AdminBtn;
