import { useState, useEffect } from "react";
import AdminPage from "src/components/Academics/AdminPage";
import AdminTop from "src/components/Academics/adminTop";
import Login from "src/components/Academics/login";

import { SHA256 } from "crypto-js";
import UserPage from "src/components/Academics/UserPage";

const Academics = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [docId, setDocId] = useState("");

  useEffect(() => {
    let user = localStorage.getItem("User");
    let admin = localStorage.getItem("Admin");
    let hashA= SHA256('alzahra').toString();
    let hashB= SHA256('User').toString();

    if (user && user==hashB) {
      setIsOpen(true);
    } else if (admin && admin==hashA) {
      setIsOpen(true);
      setIsAdmin(true)
    }
  }, []);


  return !isOpen ? (
    <Login setIsOpen={setIsOpen} setIsAdmin={setIsAdmin} setDocId={setDocId} />
  ) : isAdmin ? (
    <AdminPage setIsAdmin={setIsAdmin} setIsOpen={setIsOpen} />
  ) : (
    <div className="flex flex-col h-full">
      <UserPage docId={docId} setIsOpen={setIsOpen} />
      {/* <div className=" bg-blue-300 flex-grow">
        <h1>Student Details</h1>
        <ul>
          {students.map((student) => (
            <li key={student.id}>
              {student.name} - {student.dob}
            </li>
          ))}
        </ul>
      </div> */}
    </div>
  );
};

export default Academics;
