import React from "react";

const Loading = () => {
  return (
    <div className="flex justify-center items-center my-28">
      <div className="rounded-md h-12 w-12 border-4 border-t-4 border-title-400 animate-spin absolute"></div>
    </div>
  );
};

export default Loading;
