import React, { useState, useEffect } from "react";
import { adminDetail } from "src/database/UserDetails";
import { editDocument } from "src/database/operations";

const ChangePass = ({ setIsModal }) => {
  const [adminData, setAdminData] = useState(null);
  const [error, setError] = useState(null);
  const [name, setName] = useState('');
  const [pass, setPass] = useState('');

  const handleCancel = () => {
    setIsModal(false);
  };

  const handleUpdate = async () => {
    if (adminData) {
      const updatedData = {
        username: name,
        password: pass
      };
      try {
        await editDocument('users', 'admin', updatedData);
        setIsModal(false); // Close the modal after successful update
      } catch (error) {
        console.error("Error updating admin details: ", error);
        setError("An error occurred while updating admin details");
      }
    }
  };
  

  useEffect(() => {
    const fetchAdminDetails = async () => {
      try {
        const result = await adminDetail();
        if (result.id) {
          setAdminData(result);
          setName(result.username);
          setPass(result.password);
        } else {
          setError(result.message);
        }
      } catch (error) {
        setError("An error occurred while fetching admin details");
        console.error(error);
      }
    };
    fetchAdminDetails();

  }, []);

  return (
    <div className="backdrop-blur-sm fixed flex items-center inset-0 justify-center">
      <div className="w-72 h-52 rounded-md bg-title-400">
        <h2 className=" text-center mt-3 text-xl font-semibold">
          Admin Authentication
        </h2>
        <div className="flex flex-col items-center space-y-3 my-4">
          <div className="relative h-[35px]">
            <input
              className="pl-2 peer w-full h-full text-gray-800 bg-title-400 font-sans font-normal outline outline-0 focus:outline-0 transition-all placeholder-shown:border placeholder-shown:border-slate-800 placeholder-shown:border-t-slate-800 border-2 focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2 rounded-[7px] border-slate-800 focus:border-gray-800"
              placeholder=" "
              value={name}
              // onChange={(e) => setName(e.target.value)}
              readOnly
            />
            <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-gray-800 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text--gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-800 peer-focus:text-gray-900 before:border-slate-800 peer-focus:before:!border-gray-900 after:border-slate-800 peer-focus:after:!border-gray-900">
              Username
            </label>
          </div>
          <div className="relative h-[35px]">
            <input
              className="pl-2 peer bg-title-400 w-full h-full text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 transition-all placeholder-shown:border placeholder-shown:border-slate-800 placeholder-shown:border-t-slate-800 border-2 focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-0 rounded-[7px] border-slate-800 focus:border-gray-800"
              placeholder=" "
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
            <label className="flex w-full h-full select-none pointer-events-none absolute left-0 bottom-2 font-normal !overflow-visible truncate peer-placeholder-shown:text-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text--gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-slate-800 peer-focus:text-gray-900 before:border-slate-800 peer-focus:before:!border-gray-900 after:border-slate-800 peer-focus:after:!border-gray-900">
              Password
            </label>
          </div>
        </div>
        <div className="flex justify-between p-2 px-4">
          <button
            className="w-[80px] bg-custom-500 font-semibold py-1 rounded-md text-title-400"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="w-[80px] bg-custom-200 font-semibold py-1 rounded-md text-title-400"
            onClick={handleUpdate}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePass;
