import { useState, useEffect } from "react";
import { collection, getDoc,getDocs,doc } from "firebase/firestore";
import { db } from "src/utils/firebase";

const useUserDetails = ({ username, password }) => {
  const [authResult, setAuthResult] = useState({
    isAuthenticated: null,
    message: "",
    isAdmin:false,
    userDocId:''
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, "users");
        const userSnapshot = await getDocs(usersCollection);      
        const usersList = userSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const user = usersList.find((user) => user.username === username);
        if (user) {
          if (user.password === password) {
            // console.log(user.id)
            if (user.id === "admin") {
              setAuthResult({
                isAuthenticated: true,
                message: "Authentication successful",
                isAdmin: true,
              });
            } else {
              setAuthResult({
                isAuthenticated: true,
                message: "Authentication successful",
                isAdmin: false,
                userDocId:user.studentDocId
              });
            }
          } else {
            setAuthResult({
              isAuthenticated: false,
              message: "Incorrect password",
            });
          }
        } else {
          setAuthResult({ isAuthenticated: false, message: "User not found" });
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
        setAuthResult({
          isAuthenticated: false,
          message: "Error fetching user data",
        });
      }
    };

    fetchUsers();
  }, [username, password]);

  return authResult;
};

const adminDetail = async () => {
  try {
    const adminDocRef = doc(db, "users", "admin");
    const adminDoc = await getDoc(adminDocRef);

    if (adminDoc.exists()) {
      return {
        id: adminDoc.id,
        ...adminDoc.data(),
      };
    } else {
      return { message: "Admin document not found" };
    }
  } catch (error) {
    console.error("Error fetching admin data: ", error);
    return { message: "Error fetching admin data" };
  }
};


export { useUserDetails,adminDetail };