import {useState,useEffect} from "react";
import { GrFormNext,GrFormPrevious } from "react-icons/gr";


const Carousel = ({ children: slides,autoslide=false,autoslideInterval=3000, }) => {
  const [curr,setCurr] = useState(0);

  const prev = () =>{
    setCurr((curr)=> (curr === 0 ? slides.length-1 : curr-1))
  }
  const next = () =>{
    setCurr((curr)=> (curr === slides.length-1? 0 : curr+1))
  }

  useEffect(()=>{
    if(!autoslide) return
    const slideInterval = setInterval(next,autoslideInterval)
    return ()=> clearInterval(slideInterval)
  },[autoslide,autoslideInterval])

  return (
    <div className="w-screen shadow overflow-hidden rounded-md">
      <div className="  relative">
        <div
          className=" flex transition-transform ease-out duration-500"
          style={{ transform: `translateX(-${curr * 100}%)` }}
        >
          {slides}
        </div>
        <div className="carousel-controls absolute inset-0 flex items-center justify-between p-4">
          <button onClick={prev} className="p-[1px] rounded-full shadow bg-white/40">
            <GrFormPrevious size={40} />
          </button>
          <button onClick={next} className="p-[1px] rounded-full shadow bg-white/40">
            <GrFormNext size={40} />
          </button>
        </div>
        <div className="carousel-indicators absolute bottom-4 right-0 left-0 flex items-center justify-center gap-2">
          {slides.map((_, i) => (
            <div
              key={i}
              className={`transition-all w-2 h-2 bg-white rounded-full ${
                curr === i ? "p-2" : "bg-opacity-50"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
