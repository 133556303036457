import React from "react";

import Carousel from "src/components/Home/Carousel";
import Quotes from "src/components/Home/Quotes";
import Updates from "src/components/Home/Updates";

const Home = () => {
  const slides = ["slidea.jpg", "slideb.jpg", "slidec.jpg"];

  return (
    <div className="lg:h-[700px] grid grid-cols-1 sm:grid-cols-1 px-3 md:px-0 lg:grid-cols-2 gap-4">
      <div className="my-8 lg:my-14 sm:col-span-1 md:px-12  lg:col-span-1 row-span-1">
        <div className="h-full flex justify-center items-center">
          <Carousel autoslide={true}>
            {slides.map((s, index) => (
              <img
                key={index}
                className="w-screen object-cover rounded-lg shadow-md"
                src={s}
              />
            ))}
          </Carousel>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4">
        <div className="row-span-2 flex justify-center items-center">
          <Quotes />
        </div>
        <div className="row-span-1 flex justify-center items-start px-4 md:px-0">
          <Updates />
        </div>
      </div>
    </div>
  );
};

export default Home;
