import React, { useState, useEffect } from "react";
import { useImpData } from "src/database/NoticeData";
import { v4 as uuidv4 } from "uuid";
import { FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { addRealtimeRecord,deleteRealtimeRecord } from "src/database/operations";
import Loading from "src/components/Loading";

const Impdates = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [topic, setTopic] = useState("");
  const [date, setDate] = useState("");

  const { imp, loading } = useImpData();
  if (loading) {
    return <Loading/>;
  }

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleAdd = async () => {
    let id = uuidv4();
    const newImp = {
      id,
      topic,
      date,
    };
    if (newImp) {
      try {
        await addRealtimeRecord("imp", newImp, id);
        setTopic("");
        setDate("");
      } catch (error) {
        console.error("Error adding news:", error);
      }
    }
    // console.log("New Date Added:", { topic, date });

    setIsOpen(false);
  };

  const handleDateChange = (e) => {
    const dateValue = new Date(e.target.value);
    const day = String(dateValue.getDate()).padStart(2, "0");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[dateValue.getMonth()];
    const year = dateValue.getFullYear();
    setDate(`${day} ${month} ${year}`);
  };

  const handleDelete = async (id) => {
    try {
      await deleteRealtimeRecord('imp',id);
    } catch (error) {
      console.error("Error deleting news:", error);
    }
  };

  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-col justify-center items-center w-3/4 md:w-5/6 my-6">
        {" "}
        <h4 className="mb-4 text-3xl font-bold text-center my-2 text-title-300">
          Important Dates
        </h4>
        <div className=" space-y-2 gap-3 grid md:grid-cols-2 lg:grid-cols-3 md:space-x-2 md:space-y-0 my-4">
          {imp.map((i) => (
            <div
              key={i.id}
              className="bg-title-700 py-1.5 h-fit rounded-md flex justify-between space-x-3 px-2 shadow-lg "
            >
              <h2 className="text-lg text-title-200 font-semibold">
                <span className="text-xl text-extra-900">{i.topic}</span> :{" "}
                <span className=" text-extra-500">{i.date}</span>
              </h2>
              <button onClick={()=>{handleDelete(i.id)}}>
                <MdDelete className=" text-custom-500 text-xl" />
              </button>
            </div>
          ))}
        </div>
       
        <div className="flex justify-end pr-6 w-full my-3 md:pr-16">
          <button
            onClick={handleOpen}
            className="bg-extra-600 p-3 rounded-full flex justify-center items-center shadow-lg"
          >
            <FaPlus className="text-lg text-extra-900" />
          </button>
        </div>
      </div>
      {isOpen ? (
        <div
          id="popup"
          className="fixed inset-0 flex items-center justify-center z-50"
        >
          <div className="absolute inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm"></div>
          <div className="relative flex flex-col items-center justify-center p-2 px-4 space-y-2 bg-extra-200 z-10 rounded-md">
            <h3 className="text-lg text-slate-800 font-semibold">Add News</h3>
            <div className="flex flex-col space-y-2">
              <input
                type="text"
                className="h-[36px] pl-2 placeholder-slate-800 border border-extra-100 bg-extra-400"
                placeholder="Topic"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
              />
              <input type="date" className="p-1 h-[36px] border border-extra-100 bg-extra-400" onChange={handleDateChange} />
            </div>
            <div className="w-full flex justify-between items-center text-white font-semibold">
              <button
                onClick={() => setIsOpen(false)}
                className="bg-custom-500 w-[80px] py-1 px-2 my-1 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleAdd}
                className="bg-custom-200 py-1 w-[80px] px-2 my-1 rounded-md"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Impdates;