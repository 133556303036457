import Navbar from "./navbar"
import Footer from "./footer"

const Layout = ({children}) => {
    return (
      <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow bg-custom-500">{children}</main>
      <Footer />
    </div>
    )
  }
  
  export default Layout