import React, { useState, useEffect } from "react";
import { useNewsData } from "src/database/NoticeData";
import Loading from "../Loading";

const Quotes = () => {
  const { news, loading } = useNewsData();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    if (!loading && news.length > 0) {
      const interval = setInterval(() => {
        setFade(false);
        setTimeout(() => {
          setCurrentIndex((prevIndex) => (prevIndex === news.length - 1 ? 0 : prevIndex + 1));
          setFade(true);
        }, 600);
      }, 5000); // Change news item every 5 seconds

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [news, loading]);

  if (loading || !news || news.length === 0) {
    return <Loading/>;
  }

  return (
    <div className="newsCard w-[300px] md:w-[600px] min-h-[280px] rounded-md bg-slate-400 text-white py-2 lg:mt-2">
      <h2 className="font-mono text-center py-1 text-2xl">Live News</h2>
      <div className={`flex flex-col px-4 transition-opacity duration-500 ${fade ? 'opacity-100' : 'opacity-0'}`}>
        <h3 className="text-xl font-bold pt-2 mb-3">{news[currentIndex].title}</h3>
        <p className="text-sm md:text-lg font-semibold md:font-medium">
          {news[currentIndex].description}
        </p>
      </div>
    </div>
  );
};

export default Quotes;