import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDtrBcY5qTkSfUFEaYasF3mNxji-tMAM7s",
  authDomain: "madaralzahra.firebaseapp.com",
  projectId: "madaralzahra",
  databaseURL: "https://madaralzahra-default-rtdb.asia-southeast1.firebasedatabase.app", 
  storageBucket: "madaralzahra.appspot.com",
  messagingSenderId: "182736313162",
  appId: "1:182736313162:web:a930bfb2d17a903af9bfb3"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const rdb = getDatabase(app);

export {db,rdb};