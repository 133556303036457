import React, { useState } from "react";
import { FaUser, FaEye, FaEyeSlash } from "react-icons/fa";
import { useUserDetails } from "src/database/UserDetails"; 
import { SHA256 } from "crypto-js";

const Login = ({ setIsOpen, setIsAdmin,setDocId }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
 

  const { isAuthenticated, message,isAdmin,userDocId } = useUserDetails({ username, password });

  const handleLogin = async () => {
    
    if (isAuthenticated) {
      setIsOpen(true);
      if (username=='alzahra'){
        setIsAdmin(isAdmin);
        let hashUser=  await SHA256(username).toString();
        localStorage.setItem("Admin", hashUser);
      }
      else{
        let hashUser=  await SHA256("User").toString();
        localStorage.setItem("User", hashUser);
        setDocId(userDocId);
      }
    } else {
     
      alert(message);
    }
  };


  return (
    <div className="flex justify-center items-center h-full md:py-28 py-10">
      <div className="flex flex-col bg-custom-600 border-title-400 rounded-md shadow-xl border-2 py-8 space-y-4 px-10">
        <h4 className="mb-4 text-xl text-center font-mono text-title-400">Academic Login</h4>
        <div className="relative">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <FaUser className="text-lime-900" />
          </span>
          <input
            className="p-[4px] pl-8 border-2 focus:border-title-200 outline-none rounded-md w-full placeholder-slate-500"
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="relative">
          <span className="absolute inset-y-0 right-0 flex items-center pr-3">
            <button onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <FaEye className="text-lime-900" /> : <FaEyeSlash className="text-lime-900" />}
            </button>
          </span>
          <input
            className="text-lime-900 p-[4px] pl-4 border-2 focus:border-title-200 outline-none rounded-md w-full placeholder-slate-500"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="pt-3">
          <button onClick={handleLogin} className="w-full text-center bg-title-300 h-8 font-mulish font-bold rounded-md">
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
