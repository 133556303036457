import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SubjectData, deleteSubject } from "src/database/SubjectDetails";
import { addRealtimeRecord,deleteMarkRecord } from "src/database/operations";

import { MdDelete } from "react-icons/md";

const SubjectPage = () => {
  const { subject } = useParams();
  const [subjectData, setSubjectData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [studentId, setStudentId] = useState("");
  const [mark, setMark] = useState("");
  const [grade, setGrade] = useState("");
  const [students, setStudents] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubject = async () => {
      try {
        const data = await SubjectData(`subjects/${subject.toLowerCase()}`);
        if (data && Object.keys(data).length > 0) {
          setSubjectData(data);
        } else {
          setSubjectData(null);
        }
        setLoading(false);
      } catch (e) {
        console.error("Error fetching subject: ", e);
        setLoading(false);
      }
    };

    fetchSubject();
  }, [subject]);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const studentData = await SubjectData(`subjects/${subject.toLowerCase()}/marks`);
        if (studentData) {
          const studentList = Object.keys(studentData).map((studentId) => ({
            studentId,
            mark: studentData[studentId].mark,
            grade: studentData[studentId].grade,
          }));
          studentList.sort((a, b) => {
            // Extract numeric parts from the student IDs
            const numericA = parseInt(a.studentId.match(/\d+/)[0]);
            const numericB = parseInt(b.studentId.match(/\d+/)[0]);

            // Compare numerically if both IDs have numeric parts
            if (!isNaN(numericA) && !isNaN(numericB)) {
              return numericA - numericB;
            }
            // Fallback to regular string comparison if numeric parts are not directly comparable
            return a.studentId.localeCompare(b.studentId);
          });
          setStudents(studentList);
        } else {
          setStudents([]);
        }
      } catch (error) {
        console.error("Error fetching student data: ", error);
      }
    };

    fetchStudents();
  }, [subject]);


  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleDeleteConfirmation = () => {
    setShowDeleteConfirmation(!showDeleteConfirmation);
  };

  const confirmDelete = async () => {
    try {
      await deleteSubject("subjects", subject.toLowerCase());
      navigate("/subjects");
    } catch (e) {
      console.error("Error deleting subject: ", e);
    }
    setShowDeleteConfirmation(false);
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    const newStudent = {
      studentId,
      mark,
      grade,
    };
    try {
      // Construct the correct path for adding a new record
      const path = `subjects/${subject.toLowerCase()}/marks`;
      await addRealtimeRecord(path, newStudent, studentId);
      
      // Update local state with the new student
      setStudents([...students, newStudent]);
      setStudentId("");
      setMark("");
      setGrade("");
      setIsPopupOpen(false);
    } catch (error) {
      console.error("Error adding student record: ", error);
    }
  };

  const handleDelete = async (studentIdToDelete) => {
    try {
      const path = `subjects/${subject.toLowerCase()}/marks/${studentIdToDelete}`;
      await deleteMarkRecord(path); // Assuming you have a deleteRealtimeRecord function
      const updatedStudents = students.filter(student => student.studentId !== studentIdToDelete);
      setStudents(updatedStudents);
    } catch (error) {
      console.error("Error deleting student record: ", error);
    }
  };
  

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!subjectData) {
    return (
      <h2 className="text-center mt-2 text-xl text-title-400">
        Subject not available !
      </h2>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center h-full">
      <h1 className="text-4xl font-bold my-4 capitalize text-title-400">
        {subject}
      </h1>
      <div className="flex justify-between w-full px-14 lg:px-44">
        <button
          onClick={toggleDeleteConfirmation}
          className="bg-title-400 px-4 py-2 rounded mt-4 font-bold"
        >
          DEL
        </button>
        <button
          onClick={togglePopup}
          className="bg-title-400 px-4 py-2 rounded mt-4 font-bold"
        >
          ADD
        </button>
      </div>
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
          <div className="bg-title-400 p-4 rounded-lg max-w-md">
            <h2 className="text-2xl font-bold mb-3 text-center">Add Marks</h2>
            <div className="mb-3">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="studentId"
              >
                Student ID
              </label>
              <input
                type="text"
                id="studentId"
                value={studentId}
                onChange={(e) => setStudentId(e.target.value)}
                className="shadow appearance-none border border-custom-700 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="mark"
              >
                Mark
              </label>
              <input
                type="number"
                id="mark"
                value={mark}
                onChange={(e) => setMark(e.target.value)}
                className="shadow appearance-none border border-custom-700 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="grade"
              >
                Grade
              </label>
              <input
                type="text"
                id="grade"
                value={grade}
                onChange={(e) => setGrade(e.target.value)}
                className="shadow appearance-none border border-custom-700 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>
            <div className="flex justify-between text-white font-semibold">
              <button
                onClick={togglePopup}
                className="bg-custom-500  px-4 py-2 rounded w-[115px]"
              >
                Cancel
              </button>
              <button
                onClick={handleAdd}
                className="bg-custom-200 px-4 py-2 rounded w-[115px]"
              >
                Add Mark
              </button>
            </div>
          </div>
        </div>
      )}
      {showDeleteConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg max-w-md">
            <p className="text-xl font-bold mb-3">Confirm Delete</p>
            <p className="mb-3">Are you want to delete {subject} ?</p>
            <div className="flex justify-end">
              <button
                onClick={toggleDeleteConfirmation}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="md:w-full  md:max-w-xl lg:max-w-3xl my-4 overflow-hidden">
        <h2 className="text-2xl font-bold mb-4 text-center text-title-200">
          Mark List
        </h2>
        {students.length === 0 ? (
          <p className="text-center text-slate-300">No marks added yet.</p>
        ) : (
          <div className="overflow-y-auto max-h-[400px]">
            <table className="min-w-full bg-white border-gray-300 border shadow-lg rounded-sm">
              <thead className="bg-gray-200 sticky top-0">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Student ID
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Mark
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Grade
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider hidden md:block">
                    DEL
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {students.map((student, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {student.studentId}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {student.mark}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {student.grade}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap hidden md:block">
                      <button onClick={()=>handleDelete(student.studentId)} ><MdDelete className="text-lg text-custom-500"/></button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubjectPage;
